@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');

*{
  padding: 0px;
  margin: 0px;
  box-sizing:border-box;
}
body{
  font-family: 'Poppins', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,oxygen,ubuntu,cantarell,"open-sans","Helvetica Neue",sans-serif;
}
html,body{
  scroll-behavior: smooth;
  overflow-x:hidden;  
}
@tailwind base;
@tailwind components;
@tailwind utilities;